import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

import Nav from './components/nav/nav'
import Home from './components/home/home'
import About from './components/about/about'
import Contact from './components/contact/contact'
import Gallery from './components/gallery/gallery'
import Footer from './components/footer/footer'

const firebaseConfig = {
  apiKey: "AIzaSyBR3AyE891X5qOw0sD8Jy0t7VMJTOEA6mg",
  authDomain: "henry-irvine.firebaseapp.com",
  databaseURL: "https://henry-irvine-default-rtdb.firebaseio.com",
  projectId: "henry-irvine",
  storageBucket: "henry-irvine.appspot.com",
  messagingSenderId: "198931492943",
  appId: "1:198931492943:web:a256ba620ba962f48c7a3a",
  measurementId: "G-FSRJXC5G5T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Router>
        <Nav />
        <div id="content">
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/gallery">
              <Gallery />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  )
}

export default App
