import './home.scss'
import logo from '../../images/Peter_Icon.png'

export default function Home() {
  return (
    <div id="homepage">
      <img src={logo} alt="Minnedevs logo"  id="homeImage" />
      <h1>Henry Irvine</h1>
      <h2>a Twin Cities based little man</h2>
    </div>
  )
}